import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "QNAP Container Station & Shinobi CCTV",
  "path": "/Frequently_Asked_Question/QNAP_Container_Shinobi/",
  "dateChanged": "2022-02-02",
  "author": "Mike Polinowski",
  "excerpt": "How to use QNAP Container Station to use Shinobi CCTV software ?",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='FAQs - QNAP Container Station & Shinobi CCTV' dateChanged='2022-02-02' author='Mike Polinowski' tag='INSTAR IP Camera' description='How to use QNAP Container Station to use Shinobi CCTV software ?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/QNAP_Container_Shinobi/' locationFR='/fr/Frequently_Asked_Question/QNAP_Container_Shinobi/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "qnap-container-station--shinobi-cctv-software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#qnap-container-station--shinobi-cctv-software",
        "aria-label": "qnap container station  shinobi cctv software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`QNAP Container Station & Shinobi CCTV Software`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: How to use QNAP Container Station to use `}<a parentName="p" {...{
        "href": "/en/Software/Linux/Shinobi_Open_Source_CCTV/"
      }}>{`Shinobi CCTV`}</a>{` ?`}</p>
    <p><a parentName="p" {...{
        "href": "https://shinobi.video/docs/start"
      }}>{`Official Shinobi Guide`}</a></p>
    <p><strong parentName="p">{`A`}</strong>{`: Newer QNAP NAS systems offer the possibility to run Docker containers. First, the Container Station must be installed and started on the QNAP. Docker is then also provided on the QNAP:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/efd07cfef75172d224702e4f0d8f63c7/2bef9/QNAP_Container_Station_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB0UlEQVQY0yWMz2sTQRiG988RwatepBZaqb96UCylCt6lPXjwIh5ab2LbmxRiEpuSJltNpYZiwRqktIiittiNJprYxDYbk2x2szs7883uzuzOiAk8PLzP5VX2PpYOtMqvI71ab/6o6MWyXiw3tFJj4D56saRrA8rNw5J+UPzz5fvJ18OaEs8eLeVPFnL1pxvH2m+bAMXAAiYCJhiXAReD3U/BQun5IcLURoSAr0w93Jt6sDt5f+fy3cLWTtV1eq12F8BjLKKE+l4QhpKziLOIEAqEchZhDB74nEXK9Oy7mbnC9KPC5L3N7d1KwHC7YxIacB59q9Rapu0iQvvZsez6XyPkomOYP4913+fK6O3M2J3syK30mbFn6280KSOEgHHBudj+tF/T20bH7HZ7gc9blt20bAC/1nM/V+s9RJTRidjIROzC9aXTQ/O5/L6UArngugDgEwxCyCiSYShDLgn4mHgikjqChuWEoVCGry0OXZk/f+nJqbOz6voH5FiGYSGEMYGuaTkOph5z/98RjAETihAGDEAoJlS5emNh/ObixfHH54bn0ur715tbyZWXqXQulszEEpnE8loytbaymkuk1NRqLv5cTWdfJVMv4svqRv7tP5X4h0tQI1WoAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/efd07cfef75172d224702e4f0d8f63c7/e4706/QNAP_Container_Station_01.avif 230w", "/en/static/efd07cfef75172d224702e4f0d8f63c7/d1af7/QNAP_Container_Station_01.avif 460w", "/en/static/efd07cfef75172d224702e4f0d8f63c7/7f308/QNAP_Container_Station_01.avif 920w", "/en/static/efd07cfef75172d224702e4f0d8f63c7/e3723/QNAP_Container_Station_01.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/efd07cfef75172d224702e4f0d8f63c7/a0b58/QNAP_Container_Station_01.webp 230w", "/en/static/efd07cfef75172d224702e4f0d8f63c7/bc10c/QNAP_Container_Station_01.webp 460w", "/en/static/efd07cfef75172d224702e4f0d8f63c7/966d8/QNAP_Container_Station_01.webp 920w", "/en/static/efd07cfef75172d224702e4f0d8f63c7/a9a89/QNAP_Container_Station_01.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/efd07cfef75172d224702e4f0d8f63c7/81c8e/QNAP_Container_Station_01.png 230w", "/en/static/efd07cfef75172d224702e4f0d8f63c7/08a84/QNAP_Container_Station_01.png 460w", "/en/static/efd07cfef75172d224702e4f0d8f63c7/c0255/QNAP_Container_Station_01.png 920w", "/en/static/efd07cfef75172d224702e4f0d8f63c7/2bef9/QNAP_Container_Station_01.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/efd07cfef75172d224702e4f0d8f63c7/c0255/QNAP_Container_Station_01.png",
              "alt": "How to use QNAP Container Station to use Shinobi CCTV software ?",
              "title": "How to use QNAP Container Station to use Shinobi CCTV software ?",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To use Docker, the `}<strong parentName="p">{`SSH`}</strong>{` or `}<strong parentName="p">{`Telnet`}</strong>{` access to the QNAP must be activated first - this is only needed to download the Docker images and can be `}<strong parentName="p">{`closed again`}</strong>{` afterwards:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/eb3ce57cbd8d8c2492d3debde310b8fb/0940f/QNAP_Container_Station_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABGElEQVQY04XNv0rDUBTH8byYCB3UaDRWyB8JQaWoY8En8BUcOgjiIEhx9THEQWgawVoaxKI0yU3a3nPuSW+aSCsUhxa/fIaz/DhKy+u2/eCt0+/2vj0/8PzeH8FCqz3nz71+vPjB07OvXDXuGtf3N7fN5sMjS4EmBZAU2VRkU6QcfglJk4JkuYCUjzgpa+vqllqtVLZ13frsfyXDURiziKURS5PhEBEQgEiUy1IODPf4tG46Z7Xzi0EYA4ox52MOHBAQiUgIURTF8rFm1Q3D2dwx7MNa1HknIgDOOQcAxNklpSxXpKjupWm5+r5tmkfxIEzTNEmSLMuklHmeSylXvZ2NNzRrr+pou4Ztn0RRTERhGDLGELH8rx8PuGbWgN3C1AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/eb3ce57cbd8d8c2492d3debde310b8fb/e4706/QNAP_Container_Station_02.avif 230w", "/en/static/eb3ce57cbd8d8c2492d3debde310b8fb/d1af7/QNAP_Container_Station_02.avif 460w", "/en/static/eb3ce57cbd8d8c2492d3debde310b8fb/7f308/QNAP_Container_Station_02.avif 920w", "/en/static/eb3ce57cbd8d8c2492d3debde310b8fb/67819/QNAP_Container_Station_02.avif 1154w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/eb3ce57cbd8d8c2492d3debde310b8fb/a0b58/QNAP_Container_Station_02.webp 230w", "/en/static/eb3ce57cbd8d8c2492d3debde310b8fb/bc10c/QNAP_Container_Station_02.webp 460w", "/en/static/eb3ce57cbd8d8c2492d3debde310b8fb/966d8/QNAP_Container_Station_02.webp 920w", "/en/static/eb3ce57cbd8d8c2492d3debde310b8fb/e3efe/QNAP_Container_Station_02.webp 1154w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/eb3ce57cbd8d8c2492d3debde310b8fb/81c8e/QNAP_Container_Station_02.png 230w", "/en/static/eb3ce57cbd8d8c2492d3debde310b8fb/08a84/QNAP_Container_Station_02.png 460w", "/en/static/eb3ce57cbd8d8c2492d3debde310b8fb/c0255/QNAP_Container_Station_02.png 920w", "/en/static/eb3ce57cbd8d8c2492d3debde310b8fb/0940f/QNAP_Container_Station_02.png 1154w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/eb3ce57cbd8d8c2492d3debde310b8fb/c0255/QNAP_Container_Station_02.png",
              "alt": "How to use QNAP Container Station to use Shinobi CCTV software ?",
              "title": "How to use QNAP Container Station to use Shinobi CCTV software ?",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now you can connect to the QNAP via SSH or Telnet and create the required folders for the desired container:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`ssh`}</span>{` myuser@`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`NAS_IP_ADDRESS`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` -p Shinobi/`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`streams,customAutoLoad,config,database,videos,plugins`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Now briefly check if Docker was installed successfully:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`instar@NAS587415 ~`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`$ docker version
Client:
 Version:           `}<span parentName="code" {...{
            "className": "token number"
          }}>{`20.10`}</span>{`.7-qnap3
 API version:       `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1.41`}</span>{`
 Go version:        go1.13.15
 Git commit:        50b64c4
 Built:             Tue Oct `}<span parentName="code" {...{
            "className": "token number"
          }}>{`26`}</span>{` 07:03:45 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2021`}</span>{`
 OS/Arch:           linux/amd64
 Context:           default
 Experimental:      `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span></code></pre></div>
    <p>{`And already you can download a desired Docker image - e.g. `}<a parentName="p" {...{
        "href": "/en/Software/Linux/Shinobi_Open_Source_CCTV/"
      }}>{`Kerberos`}</a>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`instar@NAS587415 ~`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`$ docker pull shinobisystems/shinobi:dev
Using default tag: latest
latest: Pulling from kerberos/kerberosDigest: sha256:dba902db8d1ba63dbd7b6ce3653c8b4e6ba482d47a0c72d3f0d0d823e44ce68e
Status: Downloaded newer image `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` kerberos/kerberos:latest
docker.io/kerberos/kerberos:latest`}</code></pre></div>
    <p>{`Back on the QNAP web interface, you can now see that the container image is ready:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bd1ab63476963c4bb2fddbe1b8740446/99661/QNAP_Container_Station_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB6ElEQVQoz5WQTWsTURSGZ+cPEFduFRFKrdlJxYi1YF0oFUrcCIqR+gPciQtXSgsq1g/augmtxumQNImVqNmYCqLgTk1Nk5lJZjLNp83XZO49987cI6MuumwPz+a88PLCI+U2LbPeKZQbulFvNrZVvbpRqBk1u2R1cpvVkvG71mzn1VperZmNVsFo/sxX83prQ2t+z5nSY1mblbUHsvblR6vT6RHqeh4CuIx5rovAPAou54JxAeBxJjj3+n2n27UpuNL4dGYs/OHYVCqS+CW4Y/cdAC4EAmUMXPx7QvwHBfa6NqVMCHS5J124mTofXh0NyYvRbx+z6y9Xkkp87ZWcjCqp1eS7WCL9WnkTS6SV+NvkWiaWSEflhKyklqPx7Kev0rkrS2OXI4GJ+YXlz+1eW7fqJb1UKKrWVrVSsYqqVlR1vVQuG6aqaqqqWVtVs2KVDbPZ2pbOXHoWvPhkODi7EFlHRJsw2x70erbjEKBsMHAchxBCAfzctgeUAqVACBDCpNHxe8GJmSOB288XM4hIqa+Hc/Ql+Xp2gjtfAFc6efbuiVN3hgK35l+8R0RCOGPebvDLN6afXgvPTU7dl5Xsv+U9lI8PXR8+evXggcm5Ryt7Xh45FBo5HNq/7/TDmSVEdBwG4O4GSvkfIU0vDEw9FxoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bd1ab63476963c4bb2fddbe1b8740446/e4706/QNAP_Container_Station_03.avif 230w", "/en/static/bd1ab63476963c4bb2fddbe1b8740446/d1af7/QNAP_Container_Station_03.avif 460w", "/en/static/bd1ab63476963c4bb2fddbe1b8740446/7f308/QNAP_Container_Station_03.avif 920w", "/en/static/bd1ab63476963c4bb2fddbe1b8740446/2fdb2/QNAP_Container_Station_03.avif 1098w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bd1ab63476963c4bb2fddbe1b8740446/a0b58/QNAP_Container_Station_03.webp 230w", "/en/static/bd1ab63476963c4bb2fddbe1b8740446/bc10c/QNAP_Container_Station_03.webp 460w", "/en/static/bd1ab63476963c4bb2fddbe1b8740446/966d8/QNAP_Container_Station_03.webp 920w", "/en/static/bd1ab63476963c4bb2fddbe1b8740446/4eac3/QNAP_Container_Station_03.webp 1098w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bd1ab63476963c4bb2fddbe1b8740446/81c8e/QNAP_Container_Station_03.png 230w", "/en/static/bd1ab63476963c4bb2fddbe1b8740446/08a84/QNAP_Container_Station_03.png 460w", "/en/static/bd1ab63476963c4bb2fddbe1b8740446/c0255/QNAP_Container_Station_03.png 920w", "/en/static/bd1ab63476963c4bb2fddbe1b8740446/99661/QNAP_Container_Station_03.png 1098w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bd1ab63476963c4bb2fddbe1b8740446/c0255/QNAP_Container_Station_03.png",
              "alt": "How to use QNAP Container Station to use Shinobi CCTV software ?",
              "title": "How to use QNAP Container Station to use Shinobi CCTV software ?",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Clicking the `}<strong parentName="p">{`+`}</strong>{` icon allows you to start a container instance:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c7049cd668e6302ec72bf609f37a1071/01645/QNAP_Container_Station_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB5UlEQVQoz3WRTWsTURSG5we4EXEhgmgXgrhxUew/cCHuuvNjIy7dK+LKFpc6Y2ticKtiSxqJgRqKRaF1kRiwVnAhhtpMJ02TuffO/f6a6ZVMWqVYn9V7Fg+85xxv4c1ie+NXGEZbnU0cb4PBYKcPmNCE8O1eTBJiFOv3QX8AmMIDCOMY//gZVmrL5Urdu3L1Xv1DkxLU7XY7nVBIYYzRWhttrDV5HoZh1kZr5ZxrtVrT0w/94Ik3NnG7XPuUpZpwCRKcIMQYM9bqHDPS9wYtlXTONZvN2dmnpdJz7+zErYXaym5mMaEQIZUj/gNjzDn3rr507cbNO3fve2MXrperHwWnGOFet7cVRRBCKaU6DCGEc25ldfXB1NSjx7536tzkfGV5N03DOIpQhCGCECCEKKX6H6Qc1m40Gr7vFwpF78SZy3PlpUzp77D9BWzEiUoo44wZY+w+Zh+lVL7z5yCYKRZL3snTl+bm685lEPHNgWj3yQ7EECLGuVRKKiWkUnuX+yv7/kyh8MwbvzhZffuecwpQ0gMJJBwRTihPKMeMYyYSyoVUNi+itT4gHz86/upFlVAMAKAEZ6lN7QFG458XZFm2tvY1CHL52JHzr19WnXNa6zRNzWFYaymlcRxDCDnn6+vfRvJv6fk6IOc+I+0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c7049cd668e6302ec72bf609f37a1071/e4706/QNAP_Container_Station_04.avif 230w", "/en/static/c7049cd668e6302ec72bf609f37a1071/d1af7/QNAP_Container_Station_04.avif 460w", "/en/static/c7049cd668e6302ec72bf609f37a1071/7f308/QNAP_Container_Station_04.avif 920w", "/en/static/c7049cd668e6302ec72bf609f37a1071/8e468/QNAP_Container_Station_04.avif 1091w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c7049cd668e6302ec72bf609f37a1071/a0b58/QNAP_Container_Station_04.webp 230w", "/en/static/c7049cd668e6302ec72bf609f37a1071/bc10c/QNAP_Container_Station_04.webp 460w", "/en/static/c7049cd668e6302ec72bf609f37a1071/966d8/QNAP_Container_Station_04.webp 920w", "/en/static/c7049cd668e6302ec72bf609f37a1071/49d62/QNAP_Container_Station_04.webp 1091w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c7049cd668e6302ec72bf609f37a1071/81c8e/QNAP_Container_Station_04.png 230w", "/en/static/c7049cd668e6302ec72bf609f37a1071/08a84/QNAP_Container_Station_04.png 460w", "/en/static/c7049cd668e6302ec72bf609f37a1071/c0255/QNAP_Container_Station_04.png 920w", "/en/static/c7049cd668e6302ec72bf609f37a1071/01645/QNAP_Container_Station_04.png 1091w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c7049cd668e6302ec72bf609f37a1071/c0255/QNAP_Container_Station_04.png",
              "alt": "How to use QNAP Container Station to use Shinobi CCTV software ?",
              "title": "How to use QNAP Container Station to use Shinobi CCTV software ?",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In the advanced settings you can specify the desired ports here - in the example below I set the `}<strong parentName="p">{`Web Port`}</strong>{` to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`8888`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1c7acfbdeb75e97a63b05c791ffd4145/8ae78/QNAP_Container_Station_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACDElEQVQoz32Qy2sTURSHZ+9GdCWClC5EcCEo6n/gQlzpThEXunStoFtbdyWJMcalj2o0bTJtGhOJ0kqhYEJMImnTNI+JaQZhmPe9d+5jnHtlMgou1B9n8d0DH+fcI2WXi0Plm6YZ31XVNjTbskzT8TCDCBuGjQBizLMsx7Icj0LbdR0XDUdqrvAhly9Jl67eW6tsqZPxRFX39yeUEt9nlFLGqO8zFmIEjFFGCRFCNBqN+fmHsXhCmj1/OyNvOrahaZqu6xhjSigJ6y/BGHPOW61WMvk4nX4qHT9364284TOCkGfbtmXbEAAIAMaYTMv7IxBCxtj6+qfrN27euXtfmj11LbP00WcEQgQAgBDaAEJMICYeYYiwgHPOAyG4EBGIer3+YG5uYSEmHTtx5XW2wgMfAKCMxl/bnU530On2u31ltzfY2e3v9YaD4WivN+z1le2drqKMG18asVg8lXoiHZm5uJgpCSEopZtbn7O5NblQzq+U1t5VVovvcysluVAOO6ulXKH8drlQrmxUq9VEIplKpaWjMxcWXxWFEOEnCY4Wmz69IAiZ8wBByDkXQgRB8MP3a7VaPP4olM+cvSzLFSEEIcSaxnWh6wJd123HmbJrmqbrhgdxHMfDuFr9LR8+ePrF83w0mf07lNLo4EEQNJvNX/KhAydfPlv6v+z7PoTQMAzTNBFC7fZ2JP8EbtouitQvnY8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1c7acfbdeb75e97a63b05c791ffd4145/e4706/QNAP_Container_Station_05.avif 230w", "/en/static/1c7acfbdeb75e97a63b05c791ffd4145/d1af7/QNAP_Container_Station_05.avif 460w", "/en/static/1c7acfbdeb75e97a63b05c791ffd4145/7f308/QNAP_Container_Station_05.avif 920w", "/en/static/1c7acfbdeb75e97a63b05c791ffd4145/8ad02/QNAP_Container_Station_05.avif 1096w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1c7acfbdeb75e97a63b05c791ffd4145/a0b58/QNAP_Container_Station_05.webp 230w", "/en/static/1c7acfbdeb75e97a63b05c791ffd4145/bc10c/QNAP_Container_Station_05.webp 460w", "/en/static/1c7acfbdeb75e97a63b05c791ffd4145/966d8/QNAP_Container_Station_05.webp 920w", "/en/static/1c7acfbdeb75e97a63b05c791ffd4145/7f3f2/QNAP_Container_Station_05.webp 1096w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1c7acfbdeb75e97a63b05c791ffd4145/81c8e/QNAP_Container_Station_05.png 230w", "/en/static/1c7acfbdeb75e97a63b05c791ffd4145/08a84/QNAP_Container_Station_05.png 460w", "/en/static/1c7acfbdeb75e97a63b05c791ffd4145/c0255/QNAP_Container_Station_05.png 920w", "/en/static/1c7acfbdeb75e97a63b05c791ffd4145/8ae78/QNAP_Container_Station_05.png 1096w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1c7acfbdeb75e97a63b05c791ffd4145/c0255/QNAP_Container_Station_05.png",
              "alt": "How to use QNAP Container Station to use Shinobi CCTV software ?",
              "title": "How to use QNAP Container Station to use Shinobi CCTV software ?",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you don't create volumes in the following step, all data - that is all recorded videos, but also the software settings - will always be deleted when the container of the software is "destroyed". If this is not a problem, you can skip this step.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$HOME`}</span>{`/Shinobi/streams:/dev/shm/streams:rw
`}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$HOME`}</span>{`/Shinobi/config:/config:rw
`}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$HOME`}</span>{`/Shinobi/customAutoLoad:/home/Shinobi/libs/customAutoLoad:rw
`}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$HOME`}</span>{`/Shinobi/database:/var/lib/mysql:rw
`}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$HOME`}</span>{`/Shinobi/videos:/home/Shinobi/videos:rw
`}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$HOME`}</span>{`/Shinobi/plugins:/home/Shinobi/plugins:rw
/etc/localtime:/etc/localtime:ro`}</code></pre></div>
    <p>{`As storage location I take the folders created before via SSH. Attention, if no data is created there during container operation, you may have to check the LINUX permissions for the selected location:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9534f5901cf9e992e1e3c2ff7475264f/8ae78/QNAP_Container_Station_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACTElEQVQozy2Ry2sTURTGZ+3CRVcuxBcI4kYUxf9ABFfiSkRcdOlfYMFlqzsnqaTtqpY+kkySyUyah0mrhVqapKmlLgL2ZbSTeSWTzMydmTv33kkyMpkefnx8HPj4OBwqmcqfnLVkRZXEtq4pwDAMHbjIcxyk901oQ49A3QCGASCxTMuyLff47DzFVdhMkXr2ciq/UbWBIUmyKEkuQsQjCCOEMSY4MAhhggnBGGMIoed5jb29DzMfo9HP1K3HbxPZHwRB23Z2d+tphmMSbCoZaCZbyOa+JtK5OMPHGT6Zzi2tpErlrYODg2h0dn5+gbr9aDLBbnkEQddVz/4c1+un+z/FZtNo/RX/nR+dnApCW1HUEFGSe329Utl8/Wby3dR76ua9V6vMhkeQZdtAloVmUzr6jbWubwHQ01qtlii2SXBBAEYIQlitVqenZ2g6Sl2782I1WfZ9H2FMAHA6HahpI+j4GBPkAgAGnjcaDkM8QobDQaOxT3+KxGJz1JXrT1fWCkEY4e2dGsuXMlwxznBrSS7DFceejzNcnOESKf7LMlMsf6/X65HI7NzcAnX1xpPl1XXf910XdbVe8DNZlWVVVlRF7QhCW5IUOdzIqiCImtavVms0PRuLzVMPHj5n2XLYvLO7x+fL+dJmmisEZAsMu37huQLLl+IMV/m2XavVaDoahCcu319aTIfNAFimCUwT6IYRoqidvq7rhtHr65rW63Y123Eajf1IZNw8cenu0mJqHHYHg8FwPKPRBb4/CtV1oWkYwDQxQoeHv8Lm/1JXFHUnVZT+AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9534f5901cf9e992e1e3c2ff7475264f/e4706/QNAP_Container_Station_06.avif 230w", "/en/static/9534f5901cf9e992e1e3c2ff7475264f/d1af7/QNAP_Container_Station_06.avif 460w", "/en/static/9534f5901cf9e992e1e3c2ff7475264f/7f308/QNAP_Container_Station_06.avif 920w", "/en/static/9534f5901cf9e992e1e3c2ff7475264f/8ad02/QNAP_Container_Station_06.avif 1096w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9534f5901cf9e992e1e3c2ff7475264f/a0b58/QNAP_Container_Station_06.webp 230w", "/en/static/9534f5901cf9e992e1e3c2ff7475264f/bc10c/QNAP_Container_Station_06.webp 460w", "/en/static/9534f5901cf9e992e1e3c2ff7475264f/966d8/QNAP_Container_Station_06.webp 920w", "/en/static/9534f5901cf9e992e1e3c2ff7475264f/7f3f2/QNAP_Container_Station_06.webp 1096w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9534f5901cf9e992e1e3c2ff7475264f/81c8e/QNAP_Container_Station_06.png 230w", "/en/static/9534f5901cf9e992e1e3c2ff7475264f/08a84/QNAP_Container_Station_06.png 460w", "/en/static/9534f5901cf9e992e1e3c2ff7475264f/c0255/QNAP_Container_Station_06.png 920w", "/en/static/9534f5901cf9e992e1e3c2ff7475264f/8ae78/QNAP_Container_Station_06.png 1096w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9534f5901cf9e992e1e3c2ff7475264f/c0255/QNAP_Container_Station_06.png",
              "alt": "How to use QNAP Container Station to use Shinobi CCTV software ?",
              "title": "How to use QNAP Container Station to use Shinobi CCTV software ?",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now you can start the container and a short time later you will see it listed in the container overview - here you can also stop or restart the container:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c5630d3fe9929f8c3ae32657f3a2fd29/525d3/QNAP_Container_Station_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACZElEQVQoz03LS0gUcQDH8b8i6sGrMEtr+yQX3Xbdp1qWlpAYyJIL1iHpYVBIEGgnrUuElHSIMOkQZF52oUNUENShYxDRJYRQWTV3W/cxr/9z5j87MxtGrw9ffrcfWNvY29qDuby0U5BKFfV7QcrnpUKZ7hTR5ra4+wOWRLSVl7fyckFUt4vq5o6Yy6vr29K3jSJ4mM3dz+YeZHJf1hXbNEsK+yRywi3bsm27zpgGIaaUYUwQRIwyhLAoimJV1DQdnLr2fvjKu56J15m3m1X0tSRLeWxp3KoZFucmpRpCBGOKEd3f/RilOqOcMQ7Grr4anXrZP5HJvlnTDZkyTScMIUqpbhhWrWabZr1Wsw3DZEzn3DJNS6NUJZBpHIycXz15diU6+mT1xed6vY6xXqlKEBLGOEJUY1zXDMOwKNUlSeXc0nVTI4wiRddrYHh8eTC1FBpafJ79uH+mvFxViqWKJENCGCJ6sSxWRBkTDWGs1RgzKOUqprvc4GBg5N7Q6cWu5O2nKx8IxuWKKitQVqCiIAgxhERRsKxAVcUIE4jwr5CKICEaiA/MheM3u8Kzd+4+S4+n+vuP9CZ7E4lkKBT2+fzOA07XQZfL5fb85vV4vD6fXxAc09PXQSJxoyc87fNenp97PHj8aOehTrfbLQiCx+NxCEJ7e3tbW1tzc0tDQwP4o7GxEQCQTqdBNDgVClzwdpy7Nfdo6MQxn88fCAQ6nE63y+V0Oh0OobW1pampCfzn3/nS5MLFyYUzY/PLS5lUaiwSifT19SWTyXg8HolEY7FYNBrt7g4Gg4f/CofDfr9/Zmb2J9Y2mUq2woE5AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c5630d3fe9929f8c3ae32657f3a2fd29/e4706/QNAP_Container_Station_07.avif 230w", "/en/static/c5630d3fe9929f8c3ae32657f3a2fd29/d1af7/QNAP_Container_Station_07.avif 460w", "/en/static/c5630d3fe9929f8c3ae32657f3a2fd29/7f308/QNAP_Container_Station_07.avif 920w", "/en/static/c5630d3fe9929f8c3ae32657f3a2fd29/e726d/QNAP_Container_Station_07.avif 1090w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c5630d3fe9929f8c3ae32657f3a2fd29/a0b58/QNAP_Container_Station_07.webp 230w", "/en/static/c5630d3fe9929f8c3ae32657f3a2fd29/bc10c/QNAP_Container_Station_07.webp 460w", "/en/static/c5630d3fe9929f8c3ae32657f3a2fd29/966d8/QNAP_Container_Station_07.webp 920w", "/en/static/c5630d3fe9929f8c3ae32657f3a2fd29/36bbb/QNAP_Container_Station_07.webp 1090w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c5630d3fe9929f8c3ae32657f3a2fd29/81c8e/QNAP_Container_Station_07.png 230w", "/en/static/c5630d3fe9929f8c3ae32657f3a2fd29/08a84/QNAP_Container_Station_07.png 460w", "/en/static/c5630d3fe9929f8c3ae32657f3a2fd29/c0255/QNAP_Container_Station_07.png 920w", "/en/static/c5630d3fe9929f8c3ae32657f3a2fd29/525d3/QNAP_Container_Station_07.png 1090w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c5630d3fe9929f8c3ae32657f3a2fd29/c0255/QNAP_Container_Station_07.png",
              "alt": "How to use QNAP Container Station to use Shinobi CCTV software ?",
              "title": "How to use QNAP Container Station to use Shinobi CCTV software ?",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can get the same info via the SSH connection:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ps`}</span>{`
IMAGE                      STATUS          PORTS                                          NAMES
kerberos/kerberos:latest   Up `}<span parentName="code" {...{
            "className": "token number"
          }}>{`17`}</span>{` seconds   `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.0`}</span>{`.0.0:8889-`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`8889`}</span>{`/tcp, `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.0`}</span>{`.0.0:8888-`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`80`}</span>{`/tcp   kerberos`}</code></pre></div>
    <p>{`The web interface of the software can now be accessed in the browser via its web port on the IP of the QNAP:`}</p>
    <blockquote>
      <p parentName="blockquote"><code parentName="p" {...{
          "className": "language-text"
        }}>{`http://{NAS_IP_ADDRESS}:8888/super`}</code></p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3367045b84be2ae83a1a1d4bf7ecccc5/c929c/QNAP_Container_Station_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.82608695652174%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABUUlEQVQoz32Sy0sCURTGRaOF4pjO3OtMK5dBOU/vaxyneQmNPSZ1EzTkqmWLJFct27QKCYpoY9g7kBb9g6EgPWCE3+K8Pr5z4KSkGk4C6GhBV6rh1E9iYJER4DLgMMFhomtuhHbZYXAGcJhIiWQki2GdFgJS8DHn46KH1mx1JSD5gHAe4nxcNmmCGJGyZlSa3uXz6/BtcvUxuX55H96Pbkfjm4fHu8+vi/GTZJni30N+OyNosWpnX2m3q502iqLGzrYW7U3pdtc7EWTJa68amKc4jzROV3OqnFPlvCpndSWrKVlFzhkKoNOZRLFQp3A3gC2fD10+dIWWV9xySrOYDx3ASLJYR8KmWTo95Psx34/hoCcOepXzY3h2xPfj4skBMOki5xLDaUvOmHKmoSw5etpWuSZdtrW0Wc1YirBgbamGRYwhI3MoZFSgGMwrIvr/JN8SvHlkaCjZ8AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3367045b84be2ae83a1a1d4bf7ecccc5/e4706/QNAP_Container_Station_08.avif 230w", "/en/static/3367045b84be2ae83a1a1d4bf7ecccc5/d1af7/QNAP_Container_Station_08.avif 460w", "/en/static/3367045b84be2ae83a1a1d4bf7ecccc5/7f308/QNAP_Container_Station_08.avif 920w", "/en/static/3367045b84be2ae83a1a1d4bf7ecccc5/203bb/QNAP_Container_Station_08.avif 1218w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3367045b84be2ae83a1a1d4bf7ecccc5/a0b58/QNAP_Container_Station_08.webp 230w", "/en/static/3367045b84be2ae83a1a1d4bf7ecccc5/bc10c/QNAP_Container_Station_08.webp 460w", "/en/static/3367045b84be2ae83a1a1d4bf7ecccc5/966d8/QNAP_Container_Station_08.webp 920w", "/en/static/3367045b84be2ae83a1a1d4bf7ecccc5/491b1/QNAP_Container_Station_08.webp 1218w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3367045b84be2ae83a1a1d4bf7ecccc5/81c8e/QNAP_Container_Station_08.png 230w", "/en/static/3367045b84be2ae83a1a1d4bf7ecccc5/08a84/QNAP_Container_Station_08.png 460w", "/en/static/3367045b84be2ae83a1a1d4bf7ecccc5/c0255/QNAP_Container_Station_08.png 920w", "/en/static/3367045b84be2ae83a1a1d4bf7ecccc5/c929c/QNAP_Container_Station_08.png 1218w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3367045b84be2ae83a1a1d4bf7ecccc5/c0255/QNAP_Container_Station_08.png",
              "alt": "How to use QNAP Container Station to use Shinobi CCTV software ?",
              "title": "How to use QNAP Container Station to use Shinobi CCTV software ?",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Default Login:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`Username `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` admin@shinobi.video
Password `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` admin`}</code></pre></div>
    <p>{`A regular admin account can then be created here:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/63e6e028881bdf95b2107a5d621daca2/e5ca1/QNAP_Container_Station_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.217391304347824%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAbUlEQVQY05WNuw7DMAwD/f9/2qFIbMWSKJtRUXQJ8hh6uI0groi0Wisi8l/IYmYRQfI0te6vtywCMV5tRsP8nh3IzP1AZiKGGRBzDD5Z3F1V78pY+xTbRXm1KRUsAFT1VzuWW49FRu28dd2ozg9vsSWZUfbd0gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/63e6e028881bdf95b2107a5d621daca2/e4706/QNAP_Container_Station_09.avif 230w", "/en/static/63e6e028881bdf95b2107a5d621daca2/d1af7/QNAP_Container_Station_09.avif 460w", "/en/static/63e6e028881bdf95b2107a5d621daca2/7f308/QNAP_Container_Station_09.avif 920w", "/en/static/63e6e028881bdf95b2107a5d621daca2/96723/QNAP_Container_Station_09.avif 1332w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/63e6e028881bdf95b2107a5d621daca2/a0b58/QNAP_Container_Station_09.webp 230w", "/en/static/63e6e028881bdf95b2107a5d621daca2/bc10c/QNAP_Container_Station_09.webp 460w", "/en/static/63e6e028881bdf95b2107a5d621daca2/966d8/QNAP_Container_Station_09.webp 920w", "/en/static/63e6e028881bdf95b2107a5d621daca2/abf82/QNAP_Container_Station_09.webp 1332w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/63e6e028881bdf95b2107a5d621daca2/81c8e/QNAP_Container_Station_09.png 230w", "/en/static/63e6e028881bdf95b2107a5d621daca2/08a84/QNAP_Container_Station_09.png 460w", "/en/static/63e6e028881bdf95b2107a5d621daca2/c0255/QNAP_Container_Station_09.png 920w", "/en/static/63e6e028881bdf95b2107a5d621daca2/e5ca1/QNAP_Container_Station_09.png 1332w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/63e6e028881bdf95b2107a5d621daca2/c0255/QNAP_Container_Station_09.png",
              "alt": "How to use QNAP Container Station to use Shinobi CCTV software ?",
              "title": "How to use QNAP Container Station to use Shinobi CCTV software ?",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`This user can then be used to access the Shinobi Dashboard:`}</p>
    <blockquote>
      <p parentName="blockquote"><code parentName="p" {...{
          "className": "language-text"
        }}>{`http://{NAS_IP_ADDRESS}:8888/admin`}</code></p>
    </blockquote>
    <p>{`Then add your cameras here:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2bf3e9ab09fa63ea7aae76963295334d/d326d/QNAP_Container_Station_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.08695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB80lEQVQoz22Qy04bQRBF+QYTOwpm6MHjnn5Vd/VjumeGRwL2sEgUYgU2kUxgxS4QCSn/EFiQfHJkjCBGlM72VNW9a8ONYU7I5jBjXDrnEJExxh+mXB3GWVnSsqRCcKVkTvK1jXcbm8Ns0H+rJEynk6bdialOKSGi1rpOOwcfpu/3DmLVVKF+IoRE8nxt0O8P+v31Xo9zfnh4EKqo0SEaSmlRFEpqrVBJIwVIoaUAwUFykAoJIQv57WCw3usxJrquq2JyvqpTqqoKEaVQkgNnSizhyxVagV2RBeNHXVc3rUFnjAGARW7GAfQSDVoKSWlJx5QL9fj2Ui5L2rZNTDVoNEaPRgUhxLvY1vtN2m3SXlvvxdCiDs54betsDI/ym16PjmmMlffBuuCcNcYopTSYh8D6CVAGJIBtstI9X6aUxhh9qAw6AKWU4pxLCfBQ2DNCLwC3tV28IqP1iIvMlI4tejRBg/sfA14bT8jWSzmECow1Fo1FLgVaZ6zX6F4A6AnJX7nsjaulxoLq7TEWpcpHLCNiK38mI5LylbYXckrBevXxSFx+Yxen9PxkNJ8V85k4Pynms9HZrDibFfMvo4uv5aeOZKtvpzoaHY6/7/68n/z4Pbm6nVzfdTf3n3/9Ob65P76+667vple305u/3enl/uaQ/AMJeYoAkMxVKAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2bf3e9ab09fa63ea7aae76963295334d/e4706/QNAP_Container_Station_10.avif 230w", "/en/static/2bf3e9ab09fa63ea7aae76963295334d/d1af7/QNAP_Container_Station_10.avif 460w", "/en/static/2bf3e9ab09fa63ea7aae76963295334d/7f308/QNAP_Container_Station_10.avif 920w", "/en/static/2bf3e9ab09fa63ea7aae76963295334d/e22f7/QNAP_Container_Station_10.avif 1197w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2bf3e9ab09fa63ea7aae76963295334d/a0b58/QNAP_Container_Station_10.webp 230w", "/en/static/2bf3e9ab09fa63ea7aae76963295334d/bc10c/QNAP_Container_Station_10.webp 460w", "/en/static/2bf3e9ab09fa63ea7aae76963295334d/966d8/QNAP_Container_Station_10.webp 920w", "/en/static/2bf3e9ab09fa63ea7aae76963295334d/760a1/QNAP_Container_Station_10.webp 1197w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2bf3e9ab09fa63ea7aae76963295334d/81c8e/QNAP_Container_Station_10.png 230w", "/en/static/2bf3e9ab09fa63ea7aae76963295334d/08a84/QNAP_Container_Station_10.png 460w", "/en/static/2bf3e9ab09fa63ea7aae76963295334d/c0255/QNAP_Container_Station_10.png 920w", "/en/static/2bf3e9ab09fa63ea7aae76963295334d/d326d/QNAP_Container_Station_10.png 1197w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2bf3e9ab09fa63ea7aae76963295334d/c0255/QNAP_Container_Station_10.png",
              "alt": "How to use QNAP Container Station to use Shinobi CCTV software ?",
              "title": "How to use QNAP Container Station to use Shinobi CCTV software ?",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0a6055c6846bcf52fcb3892d4108e509/b5a09/QNAP_Container_Station_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.17391304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACe0lEQVQoz1XSS08TYRTG8bdcBRG1FOjQdlo607m80+kVhraYCqEptAEhIkYgMV4S7wYiQiS6UTcm7hQhcWEkGI0b3fkBXLnQjRvX7v0Of1NEExcnZ/dLnvMckS5WGRoqkcsMY7tZiqMuuUIGNZXHq0xQnZ5krjbO9uPrvHp0hcmxIoqZ5NigQVcssb87I3Ha+lXaAyFEaPkZZqZAyjbo0xy67SFkziNspLHSeRL5PI6b5u2DRb5tXWKxMsKRUBxxXEH0RhA9YUSfihiII3pVhJktIZ0krrQJWy6HzDxBK0ubaiOUBJqZJGy6lEvDfNiY5UbVY9iVtCkqrUqUpn4VEYjQOjDIoGUjHMtA2jaulAR1m8NxB7/h0jRg0BzU6Y5ZVKrjLEycZOdqjdV6gZWaR9zQaVaitAT/gCIQptkfQkgp98GUIwmoOqJLQQQ1fGET0a8jgjr5fI7Lk0Vunx6nK2JS87LMFFMIf4j2UAxfI6o/jDiqIBrYPzCiIfpitEcthGpzJOGyMFXi09MLfN27w60bZ+lO5ciWi2zOj3I8quFTDsA+FV8g/D/oj+l0aBZKJke3zFI+4fH67il+vN/k5+fn7O4+ZPraEhfXLlGvj6Fqif0imoNRmpQovp7QX1CSlDYhU5Iq5lGHPTSvwLmZMu825/i+t86vLzu8eHIds1hAGSnR6Q7R0jhLo+H+KEKJIf6BB3e0kg7ZkQwRN42VyzJT8dhYrrJ9/yIfX67zZuseE9NTLJyf52S9guK49OgJOhSVzoEYnY2C/kZ2pMQ2TXQ9gWkkMAwDLR4jmiyTqS9RXZ7l5soGK6trrK6ucWaqhqFpWKZ5MI1vsfgN19Y/EQBiG6UAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0a6055c6846bcf52fcb3892d4108e509/e4706/QNAP_Container_Station_11.avif 230w", "/en/static/0a6055c6846bcf52fcb3892d4108e509/d1af7/QNAP_Container_Station_11.avif 460w", "/en/static/0a6055c6846bcf52fcb3892d4108e509/7f308/QNAP_Container_Station_11.avif 920w", "/en/static/0a6055c6846bcf52fcb3892d4108e509/493f6/QNAP_Container_Station_11.avif 1360w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0a6055c6846bcf52fcb3892d4108e509/a0b58/QNAP_Container_Station_11.webp 230w", "/en/static/0a6055c6846bcf52fcb3892d4108e509/bc10c/QNAP_Container_Station_11.webp 460w", "/en/static/0a6055c6846bcf52fcb3892d4108e509/966d8/QNAP_Container_Station_11.webp 920w", "/en/static/0a6055c6846bcf52fcb3892d4108e509/90e3c/QNAP_Container_Station_11.webp 1360w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0a6055c6846bcf52fcb3892d4108e509/81c8e/QNAP_Container_Station_11.png 230w", "/en/static/0a6055c6846bcf52fcb3892d4108e509/08a84/QNAP_Container_Station_11.png 460w", "/en/static/0a6055c6846bcf52fcb3892d4108e509/c0255/QNAP_Container_Station_11.png 920w", "/en/static/0a6055c6846bcf52fcb3892d4108e509/b5a09/QNAP_Container_Station_11.png 1360w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0a6055c6846bcf52fcb3892d4108e509/c0255/QNAP_Container_Station_11.png",
              "alt": "How to use QNAP Container Station to use Shinobi CCTV software ?",
              "title": "How to use QNAP Container Station to use Shinobi CCTV software ?",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`For further setup of the Shinobi CCTV software `}<a parentName="p" {...{
        "href": "/en/Software/Linux/Shinobi_Open_Source_CCTV/"
      }}>{`please see the Shinobi manual`}</a>{`.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      